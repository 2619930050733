import React from 'react'
import Price from '~/components/atoms/Price'
import Badge from '~/components/atoms/Badge'

interface Props {
  price: number
}

function PriceBadge({ price }: Props) {
  return (
    <Badge>
      <Price price={price} />
    </Badge>
  )
}

export default PriceBadge
