import { useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from '~/lib/hooks/redux'
import { getAccountData } from '~/state/ducks/accounts/selectors'

import AccountDrawer from './AccontDrawer'

interface Props {
  children: React.ReactNode
}

export default function AccountDrawerManager({ children }: Props) {
  const router = useRouter()
  const [show, setShow] = useState(false)
  const account = useSelector(getAccountData)
  const isNotLogin = account === null

  const handleClickChildren = useCallback(async () => {
    if (!isNotLogin) {
      setShow(true)
    }
  }, [isNotLogin])

  const handleClose = useCallback(async () => {
    if (!isNotLogin) {
      setShow(false)
    }
  }, [isNotLogin])

  // パーマリンクで通知設定を自動で開く機能
  useEffect(() => {
    const { open_notification_setting } = router.query
    if (open_notification_setting !== undefined) {
      handleClickChildren()
    }
  }, [])

  return (
    <>
      <div onClick={handleClickChildren}>{children}</div>
      <AccountDrawer show={show} onClose={handleClose} />
    </>
  )
}
