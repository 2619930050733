import React from 'react'
import styled from 'styled-components'

function NSFW() {
  return <Badge>NSFW</Badge>
}

export default NSFW

const Badge = styled.div`
  ${({ theme }) => theme.fonts.Bold14}
  display: inline-block;
  padding: 6px 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.Red};
  color: ${({ theme }) => theme.colors.OnSurface4};
`
