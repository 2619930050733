import React, { useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'

interface Props {
  label?: string
  subLabel?: string
  checked: boolean
  disabled?: boolean
  className?: string
  onChange(checked: boolean): void
}

function Switch({ label, subLabel, checked, disabled = false, className, onChange }: Props) {
  const ref = useRef<HTMLInputElement>()
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onChange(e.currentTarget.checked)
      }
    },
    [onChange, disabled]
  )

  const handleClick = useCallback(() => {
    ref.current.click()
  }, [ref])

  return (
    <Container onClick={handleClick} disabled={disabled} className={className}>
      <StyledInput
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        ref={ref}
        disabled={disabled}
      />

      {label !== undefined && (
        <LabelContainer>
          <Label>{label}</Label>
          {subLabel !== undefined && <SubLabel>{subLabel}</SubLabel>}
        </LabelContainer>
      )}

      <SwitchBody checked={checked}>
        <SwitchKnob checked={checked} />
      </SwitchBody>
    </Container>
  )
}

export default Switch

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`

const SwitchBody = styled.div<{ checked: boolean }>`
  position: relative;
  flex: none;
  width: 56px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.Primary : theme.colors.OnSurface5};
  transition: background-color 0.2s;
`

const SwitchKnob = styled.div<{ checked: boolean }>`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.2s;

  ${({ checked }) =>
    checked &&
    css`
      transform: translateX(16px);
    `}
`

const StyledInput = styled.input`
  display: none;
`

const LabelContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 16px;
`

const Label = styled.span`
  ${({ theme }) => theme.fonts.Bold16};
  color: ${({ theme }) => theme.colors.OnSurface2};
`

const SubLabel = styled.span`
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface3};
`
