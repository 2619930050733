import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import UserNameBody from './UserNameBody'
import { Size } from './types'

interface UserNameProps {
  name: string
  userId: string
  link?: boolean
  size?: Size
  primary?: boolean
}
export default function UserName({
  name,
  userId,
  link = true,
  size = 14,
  primary = false
}: UserNameProps) {
  if (link) {
    return (
      <UserNameLink
        href={{
          pathname: `/users/${userId}`
        }}
        passHref
      >
        <UserNameBody name={name} size={size} primary={primary} />
      </UserNameLink>
    )
  }
  return <UserNameBody name={name} size={size} primary={primary} />
}

const UserNameLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`
