import React from 'react'
import { numberFormatter } from '~/lib/formatter'

interface Props {
  price: number | null
  className?: string
}

function format(price: number | null) {
  if (price < 0 || price === null) {
    return '-'
  }
  return numberFormatter.format(price)
}

function Price({ price, className }: Props) {
  return <span className={className}>&yen;{format(price)}</span>
}

export default Price
