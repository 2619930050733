import * as React from 'react'
import styled from 'styled-components'
import { Size } from './types'

interface UserNameBodyProps {
  name: string
  size?: Size
  primary?: boolean
}
export default function UserNameBody({ name, size = 14, primary = false }: UserNameBodyProps) {
  return (
    <NameText size={size} primary={primary}>
      {name}
    </NameText>
  )
}

const NameText = styled.span<{ size: Size; primary: boolean }>`
  color: ${({ theme, primary }) => (primary ? theme.colors.OnSurface2 : theme.colors.OnSurface3)};
  ${({ theme, size }) =>
    size === 20 ? theme.fonts.Bold20 : size === 16 ? theme.fonts.Bold16 : theme.fonts.Bold14};
`
