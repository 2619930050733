import styled from 'styled-components'
import { useCallback } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useBusy } from '~/lib/hooks/busy'
import { useSignout } from '~/lib/hooks/auth'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { fetchAccount } from '~/state/ducks/accounts/operations'
import Switch from '~/components/atoms/Switch'
import Modal from '~/components/molecules/Modal'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import axios from '~/lib/axios'

interface Props {
  show: boolean
  onClose(): void
}

export default function AccountDrawer({ show, onClose }: Props) {
  const router = useRouter()
  const account = useSelector(getAccountData)
  const dispatch = useDispatch()
  const [_, startBusy, endBusy] = useBusy()
  const signout = useSignout()

  const handleChangeIsAdult = useCallback(
    async (checked: boolean) => {
      startBusy()
      await axios(
        {
          method: 'put',
          url: '/manage/account/adult',
          data: { isAdultEnabled: checked ? '1' : '0' }
        },
        { dispatch }
      )
      await dispatch(fetchAccount(true))
      endBusy()
    },
    [dispatch, fetchAccount]
  )

  const handleSignOut = useCallback(async () => {
    await signout()
  }, [router])

  return (
    <Modal show={show} onClose={onClose} type='slide'>
      <DrawerContainer>
        <DrawerMenuContainer>
          <DrawerMenu>
            <StyledLink href='/manage/sales' passHref>
              売上管理
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu>
            <StyledLink href='/manage/purchases' passHref>
              作品購入履歴
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu>
            <StyledSwitch
              checked={account.isAdultEnabled}
              onChange={handleChangeIsAdult}
              label='NSFWコンテンツを表示'
            />
            <Note>
              NSFWはNot Safe for
              Workの略称で、フォーマルな場や公共での視聴が不適切だと考えられるコンテンツに指定されます。表示を有効にした場合、あなたは18歳以上のユーザーとして扱われます。
            </Note>
          </DrawerMenu>
          <DrawerMenu>
            <StyledLink href='/manage/notifications' passHref>
              通知設定
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu>
            <StyledLink href='/manage/email' passHref>
              メールアドレス設定
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu>
            <StyledLink href='/manage/password' passHref>
              パスワード設定
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu>
            <StyledLink href='/manage/reauth/twitter' passHref>
              X連携
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu>
            <StyledLink href='/help' passHref>
              ヘルプ
            </StyledLink>
          </DrawerMenu>
          <DrawerMenu onClick={handleSignOut}>
            <DrawerMenuButton>ログアウト</DrawerMenuButton>
          </DrawerMenu>
        </DrawerMenuContainer>
      </DrawerContainer>
    </Modal>
  )
}

const DrawerContainer = styled.div``
const DrawerMenuContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
  justify-content: flex-start;
`

const DrawerMenu = styled.div`
  ${({ theme }) => theme.fonts.Bold16};
  padding: 8px 0;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.OnSurface2};
  text-decoration: none;
  cursor: pointer;
`

const StyledSwitch = styled(Switch)`
  color: ${({ theme }) => theme.colors.OnSurface2};
`

const DrawerMenuButton = styled.button`
  color: ${({ theme }) => theme.colors.OnSurface2};
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
  background: transparent;
  cursor: pointer;
`

const Note = styled.p`
  ${({ theme }) => theme.fonts.Bold14}
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.OnSurface5};
`
