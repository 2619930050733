import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import NextImage from 'next/image'
import { useSelector } from '~/lib/hooks/redux'
import { useItemThumbnail } from '~/lib/hooks/image'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import PriceBadge from '~/components/atoms/PriceBadge'
import PrivateBadge from '~/components/atoms/PrivateBadge'
import NSFW from '~/components/atoms/NSFW'
import { ItemData, UserData } from '~/types'
import UserIcon from '~/components/atoms/UserIcon'
import UserName from '~/components/atoms/UserName'
import Button from '~/components/atoms/Button'
import SignupModal from '~/components/organisms/SignupModal'
import AccountDrawer from '~/components/molecules/AccountDrawer'

export const sizes = [88, 156, 176, 327] as const
type Size = (typeof sizes)[number]

interface Props extends ItemData {
  size: Size
  alt: string
  link?: boolean
  layout?: 'fixed' | 'contain'
  quality?: number
  priority?: boolean
  displayInfo?: boolean
  displayAdultSettings?: boolean
  user: UserData
  isDeleted: boolean
}

export default function Thumbnail({
  itemUid,
  title,
  imageUrl,
  price,
  isAdult,
  size,
  alt,
  layout = 'fixed',
  quality,
  priority,
  link = true,
  displayInfo = false,
  displayAdultSettings = false,
  user,
  public: isPublic,
  isDeleted = false
}: Props) {
  const account = useSelector(getAccountData)
  const blur = user.userId !== account?.userId && isAdult && !account?.isAdultEnabled
  const loading = blur && account === undefined

  const [thumbnailImageUrl, handleError] = useItemThumbnail(imageUrl, size, blur)

  const item = useMemo(
    () => (
      <ItemContainer>
        {loading ? (
          <Skeleton $size={size} />
        ) : (
          <Image
            src={thumbnailImageUrl}
            width={size}
            height={size}
            alt={alt}
            quality={quality}
            priority={priority}
            onError={handleError}
            style={{
              objectFit: layout === 'contain' ? 'contain' : 'cover',
              objectPosition: layout === 'contain' ? 'center' : 'top center'
            }}
          />
        )}

        {/* アカウント情報取得中もぼかしがかかる問題への対策 */}
        {!loading && (
          <>
            {blur && <Blur />}
            {isAdult && (
              <NSFWBadgeWrapper>
                <NSFW />
              </NSFWBadgeWrapper>
            )}
          </>
        )}
        {blur && !loading && displayAdultSettings && (
          <ManageAccountMessage>
            <Message>NSFWコンテンツのため表示できません</Message>
            {account !== undefined && account !== null ? (
              <AccountDrawer>
                <Button label='設定を変更' color='overlay' size='s' onClick={() => {}} />
              </AccountDrawer>
            ) : (
              <SignupModal>
                <Button label='設定を変更' color='overlay' size='s' onClick={() => {}} />
              </SignupModal>
            )}
          </ManageAccountMessage>
        )}
        {!isPublic && <DarkMask>公開停止中</DarkMask>}
        {size === 156 && (
          <BadgeWrapper>{isPublic ? <PriceBadge price={price} /> : <PrivateBadge />}</BadgeWrapper>
        )}
        {displayInfo && (
          <InfoWrapper>
            <Title>{title}</Title>
            <UserContainer>
              <UserIcon
                size={24}
                url={user.profileImageUrl}
                userId={user.userId}
                name={user.name}
                link={false}
              />
              <UserNameContainer>
                <UserName name={user.name} userId={user.userId} link={false} />
              </UserNameContainer>
            </UserContainer>
          </InfoWrapper>
        )}
      </ItemContainer>
    ),
    [thumbnailImageUrl, blur, loading, size, displayInfo, title, price]
  )

  return link ? (
    <Container>
      <StyledLink href={isDeleted ? `/archived/items/${itemUid}` : `/items/${itemUid}`} passHref>
        {item}
      </StyledLink>
    </Container>
  ) : (
    <Container>{item}</Container>
  )
}

const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
`

const ItemContainer = styled.div`
  position: relative;
  // 空行ノード対策
  font-size: 0;
`

const ImageCss = css`
  border-radius: 16px;
`

const Image = styled(NextImage)`
  ${ImageCss};
  background: ${({ theme }) => theme.colors.Surface1};
`

const Skeleton = styled.div<{ $size: number }>`
  ${ImageCss};
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
`

const MaskCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
`

const Blur = styled.div`
  ${MaskCss};
  ${({ theme }) => theme.semantics.Blur};
`

const DarkMask = styled.div`
  ${MaskCss};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.Surface3};
  color: ${({ theme }) => theme.colors.OnSurface4};
  ${({ theme }) => theme.fonts.Bold16};
`

const NSFWBadgeWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
`

const BadgeWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`

const StyledLink = styled(Link)`
  position: relative;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const InfoWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 156px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
`
const Title = styled.div`
  ${({ theme }) => theme.fonts.Bold14};
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 32px;
  max-height: 32px;
  word-break: break-all;
`
const UserContainer = styled.div`
  display: flex;
  align-items: center;
`
const UserNameContainer = styled.div`
  margin-left: 4px;
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface5};
  max-width: 112px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ManageAccountMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  ${({ theme }) => theme.fonts.Bold16};
  color: ${({ theme }) => theme.colors.OnSurface4};
  text-align: center;
`

const Message = styled.div`
  margin-bottom: 8px;
`
