import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

function Badge({ children }: Props) {
  return (
    <Container>
      <StyledBadge>{children}</StyledBadge>
    </Container>
  )
}

export default Badge

const Container = styled.div`
  display: flex;
`

const StyledBadge = styled.div`
  ${({ theme }) => theme.fonts.Bold14};
  ${({ theme }) => theme.semantics.Overlay1};

  border-radius: 8px;
  padding: 6px 8px;
  color: ${({ theme }) => theme.colors.OnSurface4};
  border: 0;
`
